import React, { Component } from "react";
// import { View, Text, ActivityIndicator, Image } from "react-native";
// import axios from "axios";
// import { api_qrscanned } from "./Api";

import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";

// import SignUp from "./App/Signup";
// import SignupTracker from "./App/SignupTracker";
// import BubbleTea from "./App/BubbleTea";
// import Comp from "./App/Comp";
// import BuyNow from "./App/BuyNow";
// import FollowUs from "./App/FollowUs";
// import Sleeve from "./App/Sleeve";
// import ThankYou from "./App/ThankYou";
// import FilteredProducts from "./App/FilteredProducts";
import ProductPage from "./App/ProductPage";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectUrl: null,
			isLoading: true,
			isMounted: false,
			analyticsData: null,
			enterCompetition: false,
		};
	}

	render() {
		return (
			<Router>
				<Routes>
					<Route path='/' exact element={<ProductPage />} />

					{/* <Route path='/signup' exact element={<FollowUs />} />
					<Route
						path='/signupform'
						exact
						element={<SignupTracker />}
					/>
					<Route path='/bubbletea' element={<BubbleTea />} />
					<Route path='/comp' element={<Comp />} />

					<Route path='/thankyou' exact element={<ThankYou />} /> */}

					{/* The buy nows redirect to a retailer */}
					{/* <Route path='/buynow' exact element={<BuyNow />} />
					<Route
						path='/buynow/:r/:p'
						element={<FilteredProducts />}
					/>

					<Route path='/sleeve/:r' exact element={<Sleeve />} /> */}

					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			</Router>
		);
	}
}

export default App;
