import React, { useState, useEffect } from "react";
import {
	View,
	Text,
	TextInput,
	StyleSheet,
	TouchableOpacity,
	Image,
	Linking,
	ScrollView,
} from "react-native";
import "./carr.css";

const images = [
	{
		url: require("../assets/wteau1.png"),
	},
	{
		url: require("../assets/s-zoom.webp"),
	},
	{
		url: require("../assets/s-zoom.file-2.png"),
	},
	{
		url: require("../assets/s-zoom.file-3.png"),
	},
];

function CarouselProducts() {
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentImageIndex(
				(prevIndex) => (prevIndex + 1) % images.length,
			);
		}, 3000);

		return () => clearInterval(interval);
	}, []);

	const handleThumbnailClick = (index) => {
		setCurrentImageIndex(index);
	};

	return (
		<View
			style={{
				width: "100%",
				backgroundColor: "#FFF",
			}}>
			<View
				style={{
					width: "100%",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<Image
					source={images[currentImageIndex].url}
					style={{
						width: "100%",
						resizeMode: "contain",
						height: window.innerWidth,
						maxHeight: 400,
						maxWidth: 400,
					}}
				/>
			</View>
			<ScrollView horizontal>
				<View
					style={{
						flexDirection: "row",
					}}>
					{images.map((src, index) => (
						<img
							key={index}
							src={src.url}
							alt={`Thumbnail ${index}`}
							className={`thumbnail ${
								index === currentImageIndex ? "active" : ""
							}`}
							onClick={() => handleThumbnailClick(index)}
						/>
					))}
				</View>
			</ScrollView>
		</View>
	);
}

export default CarouselProducts;
